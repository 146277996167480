:root {
  --PrimaryColor: hsl(26, 93%, 50%);
  --whiteColor: hsl(0, 0%, 100%);
  --greyBg: hsl(218, 10%, 90%);
  --textColor: hsl(0, 100%, 2%);
  --HoverColor: hsl(218, 100%, 58%) ;
}

.products {
  padding-top: 8rem;
  background: #f0f8ff;
}
.products .secContainer .secHeader {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.products .secContainer .secHeader .textDiv .secTitle {
  color: black;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding-bottom: 1.5rem;
}
.products .secContainer .secHeader .textDiv .secHelp {
  background: rgb(238, 174, 202);
  background: radial-gradient(circle, rgb(238, 174, 202) 0%, rgb(148, 187, 233) 100%);
  border-radius: 0.8rem;
  box-shadow: 0 2px 8px 2px black;
}
.products .secContainer .secHeader .textDiv .secHelp p {
  font-size: 15px;
  color: var(--textColor);
  font-weight: 500;
}
.products .secContainer .mainContainer {
  padding: 3rem 2rem;
  gap: 3rem;
}
.products .secContainer .mainContainer .singleContainer {
  overflow: hidden;
  border-radius: 0.8rem;
  box-shadow: 0 2px 8px 2px black;
}
.products .secContainer .mainContainer .singleContainer .imgDiv {
  height: 50vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.products .secContainer .mainContainer .singleContainer .imgDiv img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.products .secContainer .mainContainer .singleContainer .imgDiv .overlayInfo {
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 0;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.846), rgba(0, 0, 0, 0.759));
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.products .secContainer .mainContainer .singleContainer .imgDiv .overlayInfo p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 13px;
  text-align: justify;
}
.products .secContainer .mainContainer .singleContainer .containerFooter {
  padding: 0.8rem;
  background-color: whitesmoke;
}
.products .secContainer .mainContainer .singleContainer .containerFooter .containerText h3 {
  color: var(--textColor);
  font-size: 22px !important;
}
.products .secContainer .mainContainer .singleContainer .containerFooter .containerText b {
  font-size: 18px !important;
  color: #5fa36a;
  font-family: "Poppins", sans-serif !important;
}
.products .secContainer .mainContainer .singleContainer:hover .overlayInfo {
  overflow: visible;
  padding: 1rem 1.5rem;
  height: 100%;
}/*# sourceMappingURL=products.css.map */