:root {
  --PrimaryColor: hsl(26, 93%, 50%);
  --whiteColor: hsl(0, 0%, 100%);
  --blueBg: #f0f8ff;
  --textColor: hsl(0, 100%, 2%);
  --HoverColor: hsl(218, 100%, 58%);
}

.contact {
  width: 100%;
  height: 100vh;
  display: flex;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact .leftSide .contactInfoItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  gap: 4rem;
}
.contact .leftSide .contactInfoItem p {
  font-size: 22px !important;
  color: black !important;
  font-family: "Poppins", sans-serif !important;
}
@media only screen and (max-width: 768px) {
  .contact .leftSide .contactInfoItem {
    font-size: 0.5rem !important;
    background-color: whitesmoke;
    padding-top: 30%;
  }
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

@media only screen and (max-width: 768px) {
  .contact {
    flex-direction: column;
  }
  .contact::after {
    display: none;
  }
  .leftSide,
  .rightSide {
    max-width: 100%;
  }
  .rightSide {
    padding: 4rem 2rem 2rem 2rem;
  }
}/*# sourceMappingURL=contacts.css.map */