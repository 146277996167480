form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

form button {
  margin-top: 40px;
  width: 100px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}/*# sourceMappingURL=contactform.css.map */