@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:ital,wght@0,100;0,200;0,400;1,500&family=Poppins:wght@800&family=Russo+One&display=swap');

:root{
    --PrimaryColor: hsl(26, 93%, 50%);
    --whiteColor: hsl(0, 0%, 100%);
    --greyBg: hsl(218, 10%, 90%);
    --textColor: hsl(0, 100%, 2%);
    --HoverColor: hsl(218, 100%, 58%);
    --blueBg: hsl(187, 73%, 58%);
}
.navBarSection{
    .header{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgb(34,193,195);
        // background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
        box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
        border-radius: 13px;
        background: #eef8ff;
        width: 100%;
        padding: 1rem;
        z-index: 1000;

        .logoDiv{
            .logo{
                cursor: pointer;
                align-items: center;
                justify-content: center;
                color: black;
                font-size: 13px;

                .icon{
                    color: var(--PrimaryColor);
                    font-size: 28px;
                    
                }
            }
        }

        @media screen and (max-width: 768px) {
            .navBar{
                background: var(--blueBg);
                position: absolute;
                height: max-content;
                padding: 1rem;
                width: 100%;
                top: 0;
                left: -500%;
                box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
                transition: .5s ease;
                z-index: 1000;

                .navLists{
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    margin: auto;

                    .navItem{
                        padding: 1rem 0;

                        .navLink{
                            color: var(--textColor);
                            font-size: 1.1rem;
                            font-weight: 600;
                            &:hover{
                                color: var(--whiteColor);
                            }
                        }
                    }
                    
                }

                .closeNavbar{
                    position: absolute;
                    top: 1rem;
                    right: 1.5rem;
                    font-size: 30px;
                    color: var(--PrimaryColor);
                    &:hover .icon{
                        color: var(--HoverColor);
                    }
                }
            }

            .activeNavbar {
                left: 0;
            }
            
        }
        
        .toggleNavbar{
            .icon{
                font-size: 30px;
                &:hover{
                    color: var(--PrimaryColor);
                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .navBarSection{

        .toggleNavbar, .closeNavbar{
            display: none;
        }

        .header{
            padding: 1.5rem 2rem;

            .navBar{
                .navLists{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;

                    .navItem{

                        .navLink{
                            color: black;
                            padding: 0 .2rem;
                            font-size: 1.2rem;
                            font-weight: bold;
                            &:hover{
                                color: var(--PrimaryColor);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .header{
        .navBar{
            .navLists{
                .navItem{
                    padding: 0 .8rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .header{
        padding: 1rem 1.5rem !important;
    }
}