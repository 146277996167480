@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono:ital,wght@0,100;0,200;0,400;1,500&family=Poppins:wght@800&family=Russo+One&display=swap");
:root {
  --PrimaryColor: hsl(26, 93%, 50%);
  --whiteColor: hsl(0, 0%, 100%);
  --greyBg: hsl(218, 10%, 90%);
  --textColor: hsl(0, 100%, 2%);
  --HoverColor: hsl(218, 100%, 58%);
  --blueBg: hsl(187, 73%, 58%);
}

.navBarSection .header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  background: #eef8ff;
  width: 100%;
  padding: 1rem;
  z-index: 1000;
}
.navBarSection .header .logoDiv .logo {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 13px;
}
.navBarSection .header .logoDiv .logo .icon {
  color: var(--PrimaryColor);
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .navBarSection .header .navBar {
    background: var(--blueBg);
    position: absolute;
    height: -moz-max-content;
    height: max-content;
    padding: 1rem;
    width: 100%;
    top: 0;
    left: -500%;
    box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
    transition: 0.5s ease;
    z-index: 1000;
  }
  .navBarSection .header .navBar .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
  .navBarSection .header .navBar .navLists .navItem {
    padding: 1rem 0;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink {
    color: var(--textColor);
    font-size: 1.1rem;
    font-weight: 600;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink:hover {
    color: var(--whiteColor);
  }
  .navBarSection .header .navBar .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 30px;
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .closeNavbar:hover .icon {
    color: var(--HoverColor);
  }
  .navBarSection .header .activeNavbar {
    left: 0;
  }
}
.navBarSection .header .toggleNavbar .icon {
  font-size: 30px;
}
.navBarSection .header .toggleNavbar .icon:hover {
  color: var(--PrimaryColor);
}

@media screen and (min-width: 769px) {
  .navBarSection .toggleNavbar, .navBarSection .closeNavbar {
    display: none;
  }
  .navBarSection .header {
    padding: 1.5rem 2rem;
  }
  .navBarSection .header .navBar .navLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink {
    color: black;
    padding: 0 0.2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink:hover {
    color: var(--PrimaryColor);
  }
}
@media screen and (min-width: 960px) {
  .header .navBar .navLists .navItem {
    padding: 0 0.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .header {
    padding: 1rem 1.5rem !important;
  }
}/*# sourceMappingURL=navbar.css.map */